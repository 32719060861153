import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  //marginTopSubstitute,
  //smallMarginTopSubstitute,
  introSection,
  buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const DMEAPresserundePage = () => {
  return (
    <Layout pageTitle="DMEAPresserunde" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                 <p className={description}>Presserunde - April 2024</p> 
                <h1>DMEA Presserunde Patientenportal</h1>
               
                <p className="text-medium">
                Mit Andreas G. Henkel, Dipl.-Kfm. Executive Director of Program and Product Portfolio Management, the i-engineers </p>

                <p>Veröffentlichung auf Youtube:</p>
                <div className={buttonGroup}><a href="https://www.youtube.com/watch?v=0IYpOkc-JwE&t=438s"className="button-outline blue" target="_blank" rel="noreferrer">Zum Youtube Video</a> </div>
                
                <div className={buttonGroup}><a href="https://www.tie.ch/portale/"className="button-outline blue" target="_blank" rel="noreferrer"> Mehr Infos zu unseren Portalen</a> </div>
                 

                
            </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default DMEAPresserundePage;
